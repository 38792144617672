<!--
 * @Descripttion:参考文献  
 * @version:1.0.0
 * @Author: huchongyuan
 * @Date: 2021-03-11 14:34:13
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-04-20 23:57:08
-->
<template>
    <div class="Bibliography">
      <div class="BibliographyHeader">
         <Form ref="formInline" label-position="right" :label-width="100" inline>
            <FormItem prop="reference" label="参考文献">
               <Input type="text" v-model="reference" placeholder="请输入参考文献" />
            </FormItem>
            <QueryParam ref="QueryParam" />
            <FormItem>
               <Button type="primary" class="leftBtn" @click="query">查询</Button>
               <Button class="leftBtn" @click="handleReset">重置</Button>
            </FormItem>
         </Form>
      </div>
      <div class="BibliographyContent">  
         <QueryResult ref="QueryResult" :columns="columns" />
      </div>
      <statisticsModal ref="statisticsModal" />
      <PdfModal ref="PdfModal" />
      <ModifyModal ref="ModifyModal" />
    </div>
</template>
<script>
import QueryResult from '@/components/QueryResult';
import QueryParam from '@/components/QueryParam';
import Bibliography from '@/api/Bibliography';
import statisticsModal from '@/components/statisticsModal';
import PdfModal from '@/components/PdfModal';
import ModifyModal from '@/components/ModifyModal';
export default {
   name:"Bibliography",
   data(){
      return {
         "reference":"",
         "columns":[
            {"title":"序号","key":"indexNo","width":150},
            {"title":"标准号","key":"standNo",
            "width":220,
               "render":(h, params) => {
                  var value = params["row"]["standNo"];
                  let name =  params["row"]["standName"];
                  return h('Tooltip',{props:{placement: 'top'}},[
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["statisticsModal"].open({
                                    "standNo":value,
                                    "modalName":value+"　　　"+name
                                 });
                              }
                           }
                        }, value),
                     h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准详细数据")
                     ]);
               }
            },
            {"title":"标准名称","key":"standName",
            "width":300,
               "render":(h, params) => {
                  let value = params["row"]["standName"];
                  let fjUrl = params["row"]["fjUrl"];
                  return h('Tooltip',{props:{placement: 'top'}},[
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["PdfModal"].open(fjUrl);
                              }
                           }
                        }, value),
                      h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准原文")
                     ]);
               }
            },
            {"title":"参考文献标准编号","key":"referenceNo","width":220},
            {"title":"参考文献标准名称","key":"referenceName"},
            {"title":"提出修订意见",
             "width":150,
               "render":(h, params) => {
                  let {table,tableId,standNo,standName,referenceName} = params['row'];
                  return h('div', [
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["ModifyModal"].open({
                                     "table":table,
                                     "tableId":tableId,
                                     "standNo":standNo,
                                     "standName":standName,
                                     "otherInfo":referenceName
                                  });
                              }
                           }
                        }, '提出修订意见')
                     ]);
               }
            }
         ]
      }
   },
   mounted(){
      
   },
   activated(){
      console.log(this.$route.params.query);
      // 获取查询参数;
      if(this.$route.params.standName){
         let {standName} = this.$route.params;
         this.$refs["QueryParam"].setParam({standName})
      }
      if(this.$route.params.standNo){
        let {standNo} = this.$route.params;
        this.$refs["QueryParam"].setParam({standNo})
      }
      if(this.$route.params.query && this.$route.params.query=="1"){
         this.query();
      }else{
         this.handleReset();
          this.$refs["QueryResult"].query(Bibliography.query,{
             "standNo":"aaa",
             "standName":"",
             "standClass":"-1",
             "reference":""
          },true);
      }
   },
   components:{
      "QueryResult":QueryResult,
      "QueryParam":QueryParam,
      "statisticsModal":statisticsModal,
      "PdfModal":PdfModal,
      "ModifyModal":ModifyModal
   },
   methods:{
      query(){
         let queryParam = this.$refs["QueryParam"].getParam();
         let reference = this.reference;
         if(this.reference == "" && queryParam["standNo"]=="" && queryParam["standName"]==""){
            this.$Message.error('请输入关键字进行查询');
         }else{
             this.$refs["QueryResult"].query(Bibliography.query,{reference,...queryParam},true);
         }
        
      },
      handleReset(){
        this.$set(this,'reference',"");
        this.$refs["QueryParam"].reset();
      }
   }
}
</script>
<style lang="less">
.Bibliography{
   .BibliographyHeader{
      height:65px;
      .ivu-form-item{
         float:left;
      }
      .last.ivu-form-item{
         margin-left:85px;
         margin-right:0px;
      }
      .leftBtn{
         margin-right:20px;
      }
   }
}
</style>